<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>Profile Settings</h1>
    </div>
    <div v-if="userLoginType === '1'" class="vap-page-content">
      <h2 class="subtitle">Reset Password</h2>
      <div class="responsive-grid">
        <VasionInput
          id="password"
          name="password"
          v-model="password"
          title="NEW
          PASSWORD"
          type="password"
          placeholder="Enter Password..."
          inputType="top-white"
          width="100%"
          required
          :autoFocus="true"
        />
        <VasionInput
          id="confirm-password"
          name="confirm-password"
          v-model="confirmPassword"
          title="CONFIRM
          PASSWORD"
          type="password"
          placeholder="Confirm Password..."
          inputType="top-white"
          width="100%"
          required
        />
        <VasionButton
          id="change-password-button"
          name="change-password-button"
          classProp="primary"
          :isDisabled="!enablePasswordButton"
          @vasionButtonClicked="changePassword"
        >
          {{ !isLoading ? "Change Password" : "" }}
          <div v-show="isLoading" id="loader-box">
            <Loading
              :active.sync="isLoading"
              :is-full-page="false"
              loader="dots"
              color="white"
              background-color="#3D2562"
            />
          </div>
        </VasionButton>
      </div>
    </div>
    <VasionSnackbar
      id="profile-settings-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
export default {
  name: 'ProfileSettings',
  components: {
    Loading,
  },
  data: function () {
    return {
      confirmPassword: '',
      fullPage: true,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      password: '',
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    enablePasswordButton() { return this.password && this.password.trim().length > 0 && this.password === this.confirmPassword },
    userLoginType() { return this.$store.state.common.loginType },
  },
  created() {
    if (this.userLoginType !== '1') {
      this.$store.dispatch('mainViews/changeMainNav', 'profile-default-app')
      this.$router.push({ name: 'ProfileDefaultApp' })
    }
  },
  methods: {
    async changePassword() {
      if (!this.enablePasswordButton) return

      this.isLoading = true
      const resetResult = await this.$store.dispatch('common/setNewPassword', this.password)

      if (!resetResult) {
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Password Changed!'
        this.snackbarImage = true
        this.showSnackbarBool = true

        this.password = ''
        this.confirmPassword = ''
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = resetResult
        this.snackbarImage = false
        this.showSnackbarBool = true
      }
      
      setTimeout(() => { this.isLoading = false }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

#loader-box {
  min-height: 36px;
  width: 162px;
  max-width: 162px;
  z-index: 30;
}

::v-deep #change-password-button button {
  margin-left: 0px;
}

@media (min-width: $breakpoint-xl) {
  #change-password-button {
    position: relative;
    top: 17px;
  }
}
</style>
